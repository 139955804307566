import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
import HomeDesktop from './HomeDesktop'
import HomeMobile from './HomeMobile'

class Home extends Component {
  render() {
    if (this.props.isMobile()) {
      return <HomeMobile />;
    } else {
      return <HomeDesktop />;
    }
  }
}

const options = { mobileLimit: 900, shouldListenOnResize: true  }
export default connect()(withGetScreen(Home, options))
