import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import './styles.scss'

class Header extends Component {
  render() {
    return (
      <header className="header-m">
        <div className='container'>
          <div className='row'>
            <div className='col col-logo'>
              <NavLink to='/' className='logo'><img src='/funplanner-logo.svg' alt='FunPlanner' /></NavLink>
            </div>
            <div className='col col-i-want'>
              I want <b>FunPlanner</b>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default connect()(Header)
