import React, { Component } from 'react'
import './mstyles.scss'
import VisibilitySensor from "react-visibility-sensor";
import { Header, FunplanGraph } from '../../components'

class HomeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoOneFocus: false,
      isVideoTwoFocus: false,
      isVideoThreeFocus: false,
      isVideoFourFocus: false,
      isVideoFiveFocus: false,
      isContentOneFocus: false,
      isContentTwoFocus: false,
      isContentThreeFocus: false,
      isContentFourFocus: false,
    };
  }

  render() {
    // const { isContentOneFocus, isContentTwoFocus, isContentThreeFocus, isContentFourFocus } = this.state;

    return (
      <div className='home-m' id='home-m'>

        <div className='loading-screen'>
          <div>
            <div className='logo'><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
            <div className='txt'>travel with friends and family</div>
          </div>
        </div>

        <Header />

        <div className='section-m'>
          <div className='line-icon'><img src='/icons/plane.svg' alt='plane' style={{ height: '40px' }} /></div>
        </div>

        <div className='section-m section-m-1'>
          <div className='section-m-title'>
            <h2>We love to travel</h2>
          </div>
          <VisibilitySensor
            partialVisibility='middle'
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ isVideoOneFocus: true })
              }
            }}
          >
            {({ isVisible }) =>
              <div className='m-anim-video-box'>
                <div className={(this.state.isContentOneFocus) ? 'm-anim-video m-anim-video-delay' : 'm-anim-video-hidden'} >
                  <div className='mask mask-color-1'></div>
                  <div className="video video-responsive m-video-1">
                    <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-1-m.jpg" title='We Love to Travel'>
                      <source src="/videos/section-1.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>

        <div className='section-m'>
          <div className='container'>

            <VisibilitySensor
              partialVisibility='top'
              onChange={(isVisible) => {
                if (isVisible) {
                  this.setState({ isContentOneFocus: true })
                }
              }}
            >
              {({ isVisible }) =>
                <div className={(this.state.isContentOneFocus) ? 'm-animatedtext m-animatedtext-on m-animatedtext-delay' : 'm-animatedtext'}   >
                  <h4 className='color-2 semibold uppercase'>
                    We love to travel with friends or family,
                    <br/>sharing experiences and adventures,
                    <br /><br /><span className='color-1'>But it is so frustrating to organize!</span>
                  </h4>
                  <p>
                    To make everyone happy, choosing dates, activities, coordinating to create a shared plan, such a hassle!
                    <br />Bookings and budgets, what a headache!
                    <br /><br /><b>funPlanner makes group travel easy...</b>
                  </p>
                </div>
              }
            </VisibilitySensor>

          </div>
        </div>

        <div className='section-m'>
          <div className='line-icon'><img src='/icons/bus.svg' alt='bus' /></div>
        </div>

        <div className='section-m section-m-2'>
          <div className='section-m-title'>
            <h2>Explore everything</h2>
          </div>

          <VisibilitySensor
            partialVisibility='bottom'
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ isVideoTwoFocus: true })
              }
            }}
          >
            {({ isVisible }) =>
              <div className='m-anim-video-box'>
                <div className={(this.state.isVideoTwoFocus) ? 'm-anim-video' : 'm-anim-video-hidden'} >
                  <div className='mask mask-color-2'></div>
                  <div className="video video-responsive m-video-2">
                    <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-2-m.jpg" title='Explore Everything'>
                      <source src="/videos/section-2.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>

        <div className='section-m'>
          <div className='container'>
            <VisibilitySensor
              partialVisibility='bottom'
              onChange={(isVisible) => {
                if (isVisible) {
                  this.setState({ isContentTwoFocus: true })
                }
              }}
            >
              {({ isVisible }) =>
                <div className={(this.state.isContentTwoFocus) ? 'm-animatedtext m-animatedtext-on' : 'm-animatedtext'}   >
                  <h4 className='color-3 bold'>funPlanner THINKS LIKE YOU</h4>
                  <p className='font-size-lg'>
                    Start with a dream,
                    <br />Step by step,
                    <br />Turn it into reality.
                    <br /><br />
                    <b>funPlanner expands your imagination,
                    <br />opening the door to a world of experiences -</b>
                    <br />cultural events, health &amp; wellbeing, children-friendly, adventurous.</p>
                </div>
              }
            </VisibilitySensor>

          </div>
        </div>

        <div className='section-m'>
          <div className='line-icon'><img src='/icons/car.svg' alt='car' /></div>
        </div>

        <div className='section-m section-m-3'>
          <div className='section-m-title'>
            <h2>Travel together</h2>
          </div>
          <VisibilitySensor
            partialVisibility='bottom'
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ isVideoThreeFocus: true })
              }
            }}
          >
            {({ isVisible }) =>
              <div className='m-anim-video-box'>
                <div className={(this.state.isVideoThreeFocus) ? 'm-anim-video' : 'm-anim-video-hidden'} >
                  <div className='mask mask-color-3'></div>
                  <div className="video video-responsive m-video-3">
                    <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-3-m.jpg" title='Travel Together'>
                      <source src="/videos/section-3.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>

        <div className='section-m'>
          <div className='container'>
            <VisibilitySensor
              partialVisibility='bottom'
              onChange={(isVisible) => {
                if (isVisible) {
                  this.setState({ isContentThreeFocus: true })
                }
              }}
            >
              {({ isVisible }) =>
                <div className={(this.state.isContentThreeFocus) ? 'm-animatedtext m-animatedtext-on' : 'm-animatedtext'}   >
                  <h4 className='color-3 bold uppercase'>With <span className='notxttransform'>funPlanner</span>
                <br />dream, plan and travel
                <br />together</h4>
                  <p>
                    Choosing dates, experiences, together
                <br />building a plan, together
                <br />travelling and coordinating, together
                <br />booking and budgeting, together
                <br /><br />
                    <b>funPlanner the most enjoyable way to discover, plan, book and manage</b> a multi-person, multi-experience, multi-day <b>travel adventure</b>
                  </p>
                </div>
              }
            </VisibilitySensor>
          </div>
        </div>

        <div className='section-m section-bg nospace-bottom'>
          <div className='line-icon' style={{ marginBottom: '30px' }}><img src='/icons/train-white.svg' alt='train' /></div>
          <div className='container'>

            <VisibilitySensor
              partialVisibility='bottom'
              onChange={(isVisible) => {
                if (isVisible) {
                  this.setState({ isContentFourFocus: true })
                }
              }}
            >
              {({ isVisible }) =>
                <div className={(this.state.isContentFourFocus) ? 'm-animatedtext m-animatedtext-on' : 'm-animatedtext'} >
                  <h4 className='bold'>WE LOVE TO TRAVEL WITH FRIENDS AND FAMILY</h4>
                  <p>
                    <b>funPlanner</b> For any trip - from an international vacation, to a weekend getaway, to a night out on the town
                    <br /><b>funPlanner</b> Your group’s private assistant
                    <br /><b>funPlanner</b> Whenever and wherever you are travelling with friends or family
                    <br /><b>funPlanner</b> helps you organise and travel together
                    <br /><br /><b>funPlanner makes travel together fun!</b>
                  </p>
                </div>
              }
            </VisibilitySensor>

          </div>
        </div>

        <div className='section-m section-m-4' style={{ paddingTop: '0', paddingBottom: '0' }}>
          <VisibilitySensor
            partialVisibility='bottom'
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ isVideoFourFocus: true })
              }
            }}
          >
            {({ isVisible }) =>
              <div className='m-anim-video-box'>
                <div className={(this.state.isVideoFourFocus) ? 'm-anim-video' : 'm-anim-video-hidden'} >
                  <div className='mask mask-color-4'></div>
                  <div className="video video-responsive m-video-4">
                    <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-4-m.jpg" title='We Love to Travel with Friends and Family'>
                      <source src="/videos/section-4.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>

        <div className='section-m section-funplangraph section-bg-2 nospace-bottom' style={{ marginBottom: '0' }}>
          <div className='line-icon' style={{ marginBottom: '30px' }}><img src='/icons/bike.svg' alt='bike' /></div>
          <div className='container'>
            <h4 className='bold uppercase'>THE FIRST AND ONLY APP WHICH IS WITH YOU AT EVERY STEP OF TRAVEL</h4>
          </div>
          <div className="funplangraph-wrap">
            <FunplanGraph />
          </div>
          <div className='container'>
            <p className='bold txt-center uppercase'>Contact:</p>
            <p className='txt-center'>
              <a href='mailto:team@funplanner.com' target='_blank' rel="noopener noreferrer" className='bttn'>TEAM@FUNPLANNER.COM</a>
            </p>
          </div>
        </div>

        <div className='section-m section-m-5' style={{ paddingTop: '0', paddingBottom: '0' }}>
          <VisibilitySensor
            partialVisibility='bottom'
            onChange={(isVisible) => {
              if (isVisible) {
                this.setState({ isVideoFiveFocus: true })
              }
            }}
          >
            {({ isVisible }) =>
              <div className='m-anim-video-box'>
                <div className={(this.state.isVideoFiveFocus) ? 'm-anim-video' : 'm-anim-video-hidden'} >
                  <div className='mask mask-color-5'></div>
                  <div className="video video-responsive m-video-5">
                    <video playsInline={true} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-5-m.jpg" title='The First and Onaly App'>
                      <source src="/videos/section-5.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            }
          </VisibilitySensor>
        </div>

      </div >
    )
  }
}

export default HomeMobile