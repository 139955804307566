import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import logger from 'redux-logger'
import reducers from '../reducers'

import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from '../epics'
const createHistory = require('history').createBrowserHistory

const epicMiddleware = createEpicMiddleware()
const history = createHistory()

const middleware = []
middleware.push(logger)
middleware.push(routerMiddleware(history))
middleware.push(epicMiddleware)

// const store = createStore(
//   reducers,
//   applyMiddleware(...middleware)
// )

const configureStore = () => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...middleware))
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index')
      store.replaceReducer(nextRootReducer)
    })
  }

  epicMiddleware.run(rootEpic)
  return store
}

export {
  configureStore,
  history
}