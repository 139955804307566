import React, { Component } from 'react'
import { connect } from 'react-redux'
// import YouTube from 'react-youtube'
// import _ from 'lodash';
import { FunplanGraph } from '../../components'
import './styles.scss'
import './section-0.scss'
import './section-1.scss'
import './section-2.scss'
import './section-3.scss'
import './section-4.scss'
import './section-5.scss'

var ScrollDebounce = true;

class HomeDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.gotoSection = this.gotoSection.bind(this);
    this.timeout = null
  }

  componentDidMount(e) {
    // window.addEventListener("wheel", _.throttle(this.handleScroll.bind(this), 1000))

    window.addEventListener("wheel", this.wheelListener)

    if (document.getElementById('section-0')) {
      this.timeout = setTimeout(function () {
        document.getElementById('section-0').classList.remove('animated');
        document.getElementById('section-0').classList.add('inactive');
        document.getElementById('section-1').classList.remove('landing-animation');
        document.getElementById('section-1').classList.add('state');
      }, 10000);
    }
    //console.log("did mount")
  }

  componentWillUnmount(е) {
    window.removeEventListener("wheel", this.wheelListener);
    clearTimeout(this.timeout)
  };

  wheelListener = (e) => {
    if (ScrollDebounce) {
      ScrollDebounce = false;
      this.handleScroll(e)
      setTimeout(function () { ScrollDebounce = true; }, 4000);
    }
  }

  gotoSection = (num) => {
    let currsentSectionNum = this.state.index;
    let currsentSection = document.getElementById('section-' + currsentSectionNum);
    let sectionsList = document.getElementById('home').getElementsByClassName('section')
    if (currsentSectionNum !== num) {
      for (let i = 0; i < sectionsList.length; i++) {
        if (num === i) {
          sectionsList[num].classList.remove('inactive');
          sectionsList[num].classList.add('animated');
          currsentSection.classList.remove('state');
          currsentSection.classList.add('animated-off');
          setTimeout(function () {
            currsentSection.classList.remove('animated-off')
            currsentSection.classList.add('inactive')
          }, 2000);
          setTimeout(function () {
            sectionsList[num].classList.remove('animated')
            sectionsList[num].classList.add('state')
          }, 4000);
        }
      }
      this.setState({ index: num })
    }
  }


  handleScroll = (e) => {
    let dir = Math.sign(e.deltaY);
    // console.log(dir)
    // console.log("Whieel scroll up/down: " + e.deltaY);
    let indexState = this.state.index;

    if (indexState > 0 && indexState <= 5) {

      if (dir < 0) {
        if (indexState !== 1) {
          let indexBefore = (indexState === 1 ? indexState : indexState - 1);
          document.getElementById('section-' + indexState).classList.remove('state')
          document.getElementById('section-' + indexState).classList.add('animated-off')
          document.getElementById('section-' + indexBefore).classList.remove('inactive')
          document.getElementById('section-' + indexBefore).classList.add('animated')

          setTimeout(function () {
            document.getElementById('section-' + indexState).classList.remove('animated-off')
            document.getElementById('section-' + indexState).classList.add('inactive')
          }, 2000);
          setTimeout(function () {
            document.getElementById('section-' + indexBefore).classList.remove('animated')
            document.getElementById('section-' + indexBefore).classList.add('state')
          }, 4000);

          this.setState({ index: indexBefore })
        }
      }

      if (dir > 0) {
        if (indexState !== 5) {
          let indexNext = (indexState === 5 ? indexState : indexState + 1)

          document.getElementById('section-' + indexState).classList.remove('state')
          document.getElementById('section-' + indexState).classList.add('animated-off')
          document.getElementById('section-' + indexNext).classList.remove('inactive')
          document.getElementById('section-' + indexNext).classList.add('animated')

          setTimeout(function () {
            document.getElementById('section-' + indexState).classList.remove('animated-off')
            document.getElementById('section-' + indexState).classList.add('inactive')
          }, 2000);
          setTimeout(function () {
            document.getElementById('section-' + indexNext).classList.remove('animated')
            document.getElementById('section-' + indexNext).classList.add('state')
          }, 4000);

          this.setState({ index: indexNext })
        }
      }
    }
    // console.log("Active index: " + this.state.index)
  }

  // _onReady(event) {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo();
  // }


  render() {

    // const optsVideo1 = {
    //   height: '390',
    //   width: '640',
    //   playerVars: {
    //     // https://developers.google.com/youtube/player_parameters
    //     autoplay: 1,
    //     controls: 0,
    //     showinfo: 0,
    //     rel: 0,
    //     mute: 1,
    //     loop: 1,
    //     modestbranding: 1,
    //     playlist: 'JBCYdSDrERA',
    //     title: 'We Love to Travel'
    //   },
    // }, optsVideo2 = {
    //   height: '390',
    //   width: '640',
    //   playerVars: {
    //     autoplay: 1,
    //     controls: 0,
    //     showinfo: 0,
    //     rel: 0,
    //     mute: 1,
    //     loop: 1,
    //     modestbranding: 1,
    //     playlist: 'Yi8yLfdkMBU',
    //     title: 'Explore Everything'
    //   },
    // },
    //   optsVideo3 = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 0,
    //       showinfo: 0,
    //       rel: 0,
    //       mute: 1,
    //       loop: 1,
    //       modestbranding: 1,
    //       playlist: 'pSK6sWQxrmI',
    //       title: 'Travel Together'
    //     },
    //   },
    //   optsVideo4 = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 0,
    //       showinfo: 0,
    //       rel: 0,
    //       mute: 1,
    //       loop: 1,
    //       modestbranding: 1,
    //       playlist: 'UyFPnB2lArg',
    //       title: 'We Love to Travel with Friends and Family'
    //     },
    //   },
    //   optsVideo5 = {
    //     height: '390',
    //     width: '640',
    //     playerVars: {
    //       autoplay: 1,
    //       controls: 0,
    //       showinfo: 0,
    //       rel: 0,
    //       mute: 1,
    //       loop: 1,
    //       modestbranding: 1,
    //       playlist: 'Y8Edr7BX26I',
    //       title: 'The First and Onaly App'
    //     },
    //   }


    return (
      <div className='home' id='home'>

        <div className='nav-indicator'>
          <div className={(this.state.index === 1) ? 'active' : null} onClick={() => this.gotoSection(1)}></div>
          <div className={(this.state.index === 2) ? 'active' : null} onClick={() => this.gotoSection(2)}></div>
          <div className={(this.state.index === 3) ? 'active' : null} onClick={() => this.gotoSection(3)}></div>
          <div className={(this.state.index === 4) ? 'active' : null} onClick={() => this.gotoSection(4)}></div>
          <div className={(this.state.index === 5) ? 'active' : null} onClick={() => this.gotoSection(5)}></div>
        </div>

        {/* SECTION INTRO
         classNames = animated, inactive */}
        <div id="section-0" className='section section-0 animated'>
          <div id='txt-0-1' className='txt'>travel with friends and family</div>
          <div id='txt-0-2' className='txt'>Explore the world in good company </div>
        </div>


        {/* SECTION 1
        classNames = animated , state, animated-off, inactive */}
        <div id='section-1' className='section section-1 landing-animation'>

          <div id='section-header-1' className='section-header'>
            <div className='logo' onClick={() => this.gotoSection(1)}><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
            <div className='txt' onClick={() => this.gotoSection(5)}>I want <b>funPlanner</b></div>
            <div className='line-icon'><img src='/icons/plane-white.svg' alt='plane' /></div>
          </div>
          <div id='txt-1' className='txt-overlay'>We love to travel</div>

          <div id='video-box-1' className='cell-1'>
            <div id='video-logo-1' className='video-logo'>
              <div><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
              <h2 id='video-txt-1'>travel with friends and family</h2>
            </div>
            <div className="video-bg" id="video-1">

              <div className="video video-responsive">
                <video playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-1.jpg" title='Travel Together'>
                  <source src="https://funplanner.s3.eu-central-1.amazonaws.com/section-1.mp4" type="video/mp4" />
                </video>
                {/* <YouTube videoId="JBCYdSDrERA" opts={optsVideo1} /> */}
                {/* <iframe title='We Love to Travel' width="560" height="315" src="https://www.youtube.com/embed/JBCYdSDrERA?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=JBCYdSDrERA" frameBorder="0" allow="autoplay" allowFullScreen></iframe> */}
              </div>
            </div>
          </div>

          <div id="cell-text-1" className='cell-2'>
            <div>
              <h2 className='color-2'>
                WE LOVE TO TRAVEL WITH FRIENDS OR FAMILY,
                <span>SHARING EXPERIENCES AND ADVENTURES,</span>
              </h2>
              <h2 className='color-1'>BUT IT IS SO FRUSTRATING TO ORGANIZE!</h2>
              <p className='font-size-lg max-width'>
                To make everyone happy, choosing dates, activities, coordinating to create a shared plan, such a hassle!
                <br />Bookings and budgets, what a headache!
                <br /><br /><b>funPlanner makes group travel easy...</b>
              </p>
              <p className='font-size-md light color-3'><img onClick={() => this.gotoSection(2)} src='/icons/arrows.svg' alt="Scroll to explore - - - - - -" className='arrows-pointer' /></p>
              {/* <p className='font-size-md light color-3'>Scroll to explore - - - - - - </p> */}
            </div>
          </div>
        </div>


        {/* SECTION 2
        classNames = animated , state, animated-off, inactive */}
        <div id='section-2' className='section section-2 inactive'>
          <div id='section-header-2' className='section-header'>
            <div className='logo' onClick={() => this.gotoSection(1)}><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
            <div className='txt' onClick={() => this.gotoSection(5)}>I want <b>funPlanner</b></div>
            <div className='line-icon'><img src='/icons/bus-white.svg' alt='bus' /></div>
          </div>
          <div id='txt-2' className='txt-overlay'>EXPLORE EVERYTHING</div>

          <div id='video-box-2' className='cell-1'>
            <div className="video-bg" id="video-2">
              <div id='video-logo-2' className='video-logo'>
                <div><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
                <h2 id='video-txt-2'>travel with friends and family</h2>
              </div>

              <div className="video video-responsive">
                <video playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-2.jpg" title='Explore Everything'>
                  <source src="https://funplanner.s3.eu-central-1.amazonaws.com/section-2.mp4" type="video/mp4" />
                </video>

                {/* <YouTube videoId="Yi8yLfdkMBU" opts={optsVideo2} /> */}
                {/* <iframe id='video-2' title='Explore Everything' width="560" height="315" src="https://www.youtube.com/embed/Yi8yLfdkMBU?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=Yi8yLfdkMBU" frameBorder="0" allow="autoplay" allowFullScreen></iframe> */}
              </div>
            </div>
          </div>

          <div id="cell-text-2" className='cell-2'>
            <div>
              <h2 className='color-1 bold'>funPlanner THINKS LIKE YOU</h2>
              <p className='font-size-lg'>
                Start with a dream,
                <br />Step by step,
                <br />Turn it into reality.
                <br /><br />
                <b>funPlanner expands your imagination,
                <br />opening the door to a world of experiences -</b>
                <br />cultural events, health &amp; wellbeing, children-friendly, adventurous.
              </p>
              <p className='font-size-md light color-3'><img onClick={() => this.gotoSection(3)} src='/icons/arrows.svg' alt="Scroll to explore - - - - - -" className='arrows-pointer' /></p>
            </div>
          </div>
        </div>


        {/* SECTION-3
        classNames = animated , state, animated-off, inactive */}
        <div id='section-3' className='section section-3 inactive'>
          <div id='section-header-3' className='section-header'>
            <div className='logo' onClick={() => this.gotoSection(1)}><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
            <div className='txt' onClick={() => this.gotoSection(5)}>I want <b>funPlanner</b></div>
            <div className='line-icon'><img src='/icons/car.svg' alt='car' /></div>
          </div>

          <div id='txt-3' className='txt-overlay'>TRAVEL TOGETHER</div>

          <div id='video-box-3' className='cell-1'>
            <div className="video-bg" id="video-3">
              <div id='video-logo-3' className='video-logo'>
                <div><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
                <h2 id='video-txt-3'>travel with friends and family</h2>
              </div>

              <div className="video video-responsive">
                <video playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-3.jpg" title='Travel Together'>
                  <source src="https://funplanner.s3.eu-central-1.amazonaws.com/section-3.mp4" type="video/mp4" />
                </video>
                {/* <YouTube videoId="pSK6sWQxrmI" opts={optsVideo3} /> */}
                {/* <iframe title='Travel Together' width="560" height="315" src="https://www.youtube.com/embed/pSK6sWQxrmI?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=pSK6sWQxrmI" frameBorder="0" allow="autoplay" allowFullScreen></iframe> */}
              </div>
            </div>
          </div>

          <div id="cell-text-3" className='cell-2'>
            <div>
              <h2 className='color-3 bold uppercase'>
                With funPlanner
                <br />dream, plan and travel
                <br />together
              </h2>
              <p className='font-size-lg'>
                Choosing dates, experiences, together
                <br />building a plan, together
                <br />travelling and coordinating, together
                <br />booking and budgeting, together
                <br /><br />
                <b>funPlanner the most enjoyable way to discover, plan, book and manage</b> a multi-person, multi-experience, multi-day <b>travel adventure</b>
              </p>
              <p className='font-size-md light color-3'><img onClick={() => this.gotoSection(4)} src='/icons/arrows.svg' alt="Scroll to explore - - - - - -" className='arrows-pointer' /></p>
            </div>
          </div>
        </div>


        {/*  SECTION-4
        classNames = animated , state, animated-off, inactive */}
        <div id='section-4' className='section section-4 inactive'>
          <div id='section-header-4' className='section-header'>
            <div className='logo' onClick={() => this.gotoSection(1)}><img src='/funplanner-logo-2.svg' alt='funPlanner' /></div>
            <div className='txt' onClick={() => this.gotoSection(5)}>I want <b>funPlanner</b></div>
            <div className='line-icon'><img src='/icons/train-white.svg' alt='train' /></div>
          </div>

          <div id="cell-text-4" className='cell-1'>
            <div>
              <h2 className='bold'>WE LOVE TO TRAVEL WITH FRIENDS AND FAMILY</h2>
              <p className='font-size-lg'>
                <b>funPlanner</b> For any trip - from an international vacation, to a weekend getaway, to a night out on the town
                <br /><b>funPlanner</b> Your group’s private assistant
                <br /><b>funPlanner</b> Whenever and wherever you are travelling with friends or family
                <br /><b>funPlanner</b> helps you organise and travel together
                <br /><br />
                <b>funPlanner makes travel together fun!</b>
              </p>
                <p className='font-size-md light color-3'><img onClick={() => this.gotoSection(5)} src='/icons/arrows.svg' alt="Scroll to explore - - - - - -" className='arrows-pointer' /></p>
            </div>
            </div>
            <div id='video-box-4' className='cell-2'>
              <div className="video-bg" id="video-4">
                <div id='video-logo-4' className='video-logo'>
                  <div><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
                  <h2 id='video-txt-4'>travel with friends and family</h2>
                </div>
                <div className="video video-responsive">
                  <video playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-4.jpg" title='We Love to Travel with Friends and Family'>
                    <source src="https://funplanner.s3.eu-central-1.amazonaws.com/section-4.mp4" type="video/mp4" />
                  </video>
                  {/* <YouTube videoId="UyFPnB2lArg" opts={optsVideo4} /> */}
                  {/* <iframe title='We Love to Travel with Friends and Family' width="560" height="315" src="https://www.youtube.com/embed/UyFPnB2lArg?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=UyFPnB2lArg" frameBorder="0" allow="autoplay" allowFullScreen></iframe> */}
                </div>
              </div>
            </div>
          </div>


          {/*  SECTIOIN-5
        classNames = animated , state, animated-off, inactive */}
          <div id='section-5' className='section section-5 inactive'>
            <div id='section-header-5' className='section-header'>
              <div className='logo' onClick={() => this.gotoSection(1)}><img src='/funplanner-logo-2.svg' alt='funPlanner' /></div>
              <div className='txt' onClick={() => this.gotoSection(5)}>I want <b>funPlanner</b></div>
              <div className='line-icon'><img src='/icons/bike-white.svg' alt='bike' /></div>
            </div>
            <div id="cell-text-5" className='cell-1'>
              <div>
                <h2 className='bold color-base'>THE FIRST AND ONLY APP WHICH IS WITH YOU AT EVERY STEP OF TRAVEL</h2>

                <FunplanGraph />

                <div className='bottom'>
                  <div><p className='bold uppercase'>Contact:</p></div>
                  <div><a href='mailto:team@funplanner.com' target='_blank' rel="noopener noreferrer" className='bttn'>team@funplanner.com</a></div>
                </div>
              </div>
            </div>

            <div id='video-box-5' className='cell-2'>
              <div className="video-bg" id="video-5">
                <div id='video-logo-5' className='video-logo'>
                  <div><img src='/funplanner-logo.svg' alt='funPlanner' /></div>
                  <h2 id='video-txt-5'>travel with friends and family</h2>
                </div>
                <div className="video video-responsive">
                  <video playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} poster="/imgs/section-5.jpg" title='The First and Onaly App'>
                    <source src="https://funplanner.s3.eu-central-1.amazonaws.com/section-5.mp4" type="video/mp4" />
                  </video>
                  {/* <YouTube videoId="Y8Edr7BX26I" opts={optsVideo5} />
                <iframe title='The First and Onaly App' width="560" height="315" src="https://www.youtube.com/embed/Y8Edr7BX26I?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1&modestbranding=1&playlist=Y8Edr7BX26I" frameBorder="0" allow="autoplay" allowFullScreen></iframe> */}
                </div>
              </div>
            </div>

          </div>
        </div>
    )
  }
}

export default connect()(HomeDesktop)
