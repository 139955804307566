import React, { Component } from 'react'
import './styles.scss'

class FunplanGraph extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    // }
    this.toggleInfoBox = this.toggleInfoBox.bind(this);
    this.closeInfoBox = this.closeInfoBox.bind(this);
  }

  toggleInfoBox = (id) => {
    let funplanWrapper = document.getElementById('funplangraph-holder');
    let funplanglist = document.getElementById('funplang-list').getElementsByClassName('item');

    if (funplanglist[id - 1].classList.contains('item-open') === false) {
      funplanglist[id - 1].classList.add('item-open');
      funplanWrapper.style.zIndex = 4;
    } else if (funplanglist[id - 1].classList.contains('item-open') === true) {
      funplanglist[id - 1].classList.remove('item-open');
      setTimeout(function () { funplanWrapper.style.zIndex = -1 }, 200);
    }

    for (let i = 0; i < funplanglist.length; i++) {
      if (id !== i + 1) {
        funplanglist[i].classList.remove('item-open');
      }
    }
  }

  closeInfoBox = (id) => {
    let funplanWrapper = document.getElementById('funplangraph-holder')
    if (funplanWrapper) {
      funplanWrapper.style.zIndex = -1
    }
    let itemToCloseId = 'item-' + id;
    let itemToClose = document.getElementById(itemToCloseId);
    if (itemToClose) {
      itemToClose.classList.remove('item-open');
    }
  }

  render() {
    return (
      <div className='funplangraph-holder' id='funplangraph-holder'>
        <div className="funplangraph">
          <div className='graph'>
            <h3>FUNPLAN</h3>
          </div>

          <div className='funplang-list' id='funplang-list'>

            <div className='item' id='item-1'>
              <div className='item-link' onClick={() => this.toggleInfoBox(1)}>Remember</div>
              <div className='item-content' onClick={() => this.closeInfoBox(1)}>
                <div>
                  <p>
                    <b>Remembering - back home</b><br />
                  final accounting<br />
                  easily share photos with group and<br />
                  a fun map of where you went<br />
                  share to social media<br />
                  where could we go next? <br />
                    <b>dreaming begins again</b>
                  </p>
                </div>
              </div>
            </div>
            <div className='item' id='item-2'>
              <div className='item-link' onClick={() => this.toggleInfoBox(2)}>Dream</div>
              <div className='item-content' onClick={() => this.closeInfoBox(2)}>
                <div>
                  <p>
                    <b>Dreaming - where to go? who will come?</b><br />
                  Swimming in an ocean of possibilities FunPlanner knows your groups interests and preferences and helps you find amazing possibilities
                </p>
                </div>
              </div>
            </div>
            <div className='item' id='item-3'>
              <div className='item-link' onClick={() => this.toggleInfoBox(3)}>Plan</div>
              <div className='item-content' onClick={() => this.closeInfoBox(3)}>
                <div>
                  <p>
                    <b>Planning - get commitment! make it concrete! </b><br />
                  Deciding dates, experiences, getting commitment from everyone in group for dates &amp; budgets
                </p>
                </div>
              </div>
            </div>
            <div className='item' id='item-4'>
              <div className='item-link' onClick={() => this.toggleInfoBox(4)}>Book</div>
              <div className='item-content' onClick={() => this.closeInfoBox(4)}>
                <div>
                  <p>
                    <b>Booking - now it's real!</b><br />
                  Book your whole journey in one click, for everyone funPlanner handles group payment
                </p>
                </div>
              </div>
            </div>
            <div className='item' id='item-5'>
              <div className='item-link' onClick={() => this.toggleInfoBox(5)}>Travel</div>
              <div className='item-content' onClick={() => this.closeInfoBox(5)}>
                <div>
                  <p>
                    <b>travelling - fun together!</b>
                    <br />easy travel map - where are we going next?
                    <br />easy group coordination -  where is everyone?
                    <br />easy change of plans
                    <br />easy split payments
                </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
export default FunplanGraph