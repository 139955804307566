import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  CSSTransition,
  TransitionGroup
} from 'react-transition-group'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Home } from '../../screens'

class Routes extends Component {
  render() {
    return (
      <div className='dashboard-wrap'>
        <TransitionGroup>
          <CSSTransition classNames='fade' enter leave timeout={1000}>
  
            <Switch>
              <Route exact path='/' component={Home} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading }
}

export default withRouter(connect(mapStateToProps)(Routes))
